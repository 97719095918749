/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import { fuelPriceActionType } from './FuelPrice.type';

export const getInitialState = () => ({
    fuelPricing: [],
    isLoaded: false,
});

export const FuelPriceReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case fuelPriceActionType.GET_FUEL_PRICE: {
            const { ProductPricing } = action;

            return {
                ...state,
                fuelPricing: ProductPricing,
                isLoaded: true,
            };
        }

        default:
            return state;
    }
};

export default FuelPriceReducer;
