import { tanksActionType } from './Tanks.type';

/** @namespace Ennero/Store/Tanks/Tanks/Reducer/getInitialState */
export const getInitialState = () => ({
    tanksDetails: [],
});

/** @namespace Ennero/Store/Tanks/Tanks/Reducer/TanksReducer */
export const TanksReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case tanksActionType.GET_TANKS_DETAILS: {
            const { tanksDetails } = action;
            
            return {
                ...state,
                tanksDetails,
            };

            
        }
        default:
            return state;
    }
};

export default TanksReducer;
