import NotificationList from '@scandipwa/scandipwa/src/component/NotificationList';
import { Location } from 'history';
import { lazy, ReactElement, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
    CreateAccountPage,
    HomePage,
    LoginAccountPage,
    MyAccount,
    OfflineNotice,
    RouterComponent as SourceRouterComponent,
    withStoreRegex,
} from 'SourceComponent/Router/Router.component';
import UrlRewrites from 'SourceRoute/UrlRewrites';
import { HistoryState } from 'SourceUtil/History/History.type';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';

import {
    RouterBeforeItemType,
    RouterItemType,
    RouterSwitchItemType,
} from './Router.config';

export const Header = lowPriorityLazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/Header'
        )
);

export const Locations = lazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "locations" */ 'Route/Locations'
        )
);

export const PurchaseHistory = lazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "locations" */ 'Route/PurchaseHistory'
        )
);

export const Support = lazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "support" */ 'Route/Support'
        )
);

export const AddedServices = lazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "support" */ 'Route/AddedServices'
        )
);

export const FuelPrice = lazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "fuelprice" */ 'Route/FuelPricing'
        )
);

export const ForgotPasswordPage = lazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/ForgotPassword'
        )
);
export const PasswordChangePage = lazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/PasswordChangePage'
        )
);

export const Reporting = lazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "reporting" */ 'Route/Reporting'
        )
);

/** @namespace Ennero/Component/Router/Component */
export class RouterComponent extends SourceRouterComponent {
    [RouterItemType.BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10,
            name: RouterBeforeItemType.NOTIFICATION_LIST,
        },
        {
            component: <Header />,
            position: 20,
            name: RouterBeforeItemType.HEADER,
        },
    ];

    // @ts-ignore
    [RouterItemType.SWITCH_ITEMS_TYPE] = [
        {
            component: (
                <Route
                    path={withStoreRegex('/')}
                    exact
                    render={({ match }) => (
                        // @ts-ignore
                        <HomePage
                            match={match}
                            currentUrl={this.props.currentUrl}
                        />
                    )}
                />
            ),
            position: 10,
            name: RouterSwitchItemType.HOME,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account/createPassword/')}
                    // @ts-ignore
                    render={() => <PasswordChangePage />}
                />
            ),
            position: 60,
            name: RouterSwitchItemType.CHANGE_PASSWORD,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account/create/')}
                    // @ts-ignore
                    render={() => <CreateAccountPage />}
                />
            ),
            position: 61,
            name: RouterSwitchItemType.CREATE_ACCOUNT,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account/login/')}
                    // @ts-ignore
                    render={() => <LoginAccountPage />}
                />
            ),
            position: 62,
            name: RouterSwitchItemType.LOGIN,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account/forgotpassword/')}
                    // @ts-ignore
                    render={() => <ForgotPasswordPage />}
                />
            ),
            position: 63,
            name: RouterSwitchItemType.ACCOUNT_FORGOT_PASSWORD,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/fuelprice')}
                    // @ts-ignore
                    render={() => <FuelPrice />}
                />
            ),
            position: 68,
            name: RouterSwitchItemType.FUEL_PRICE,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account/:tab?')}
                    // @ts-ignore
                    render={({ match }) => <MyAccount match={match} />}
                />
            ),
            position: 76,
            name: RouterSwitchItemType.MY_ACCOUNT,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/locations')}
                    // @ts-ignore
                    render={() => <Locations />}
                />
            ),
            position: 11,
            name: RouterSwitchItemType.LOCATION,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/purchase-history')}
                    // @ts-ignore
                    render={() => <PurchaseHistory />}
                />
            ),
            position: 11,
            name: RouterSwitchItemType.PURCHASE_HISTORY,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/support')}
                    // @ts-ignore
                    render={() => <Support />}
                />
            ),
            position: 12,
            name: RouterSwitchItemType.SUPPORT,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/added-services')}
                    // @ts-ignore
                    render={() => <AddedServices />}
                />
            ),
            position: 13,
            name: RouterSwitchItemType.ADDED_SERVICES,
        },
        {
            component: (
                <Route
                    render={({ match }) => (
                        // @ts-ignore
                        <UrlRewrites
                            match={match}
                            location={
                                location as unknown as Location<HistoryState>
                            }
                        />
                    )}
                />
            ),
            position: 1000,
            name: RouterSwitchItemType.URL_REWRITES,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/reporting')}
                    // @ts-ignore
                    render={() => <Reporting />}
                />
            ),
            position: 25,
            name: RouterSwitchItemType.REPORTING,
        },
    ];

    renderBeforeItemsFallback(): ReactElement {
        return <div />;
    }

    renderMainItems(): ReactElement {
        const { isBigOffline } = this.props;

        if (!navigator.onLine && isBigOffline) {
            return <OfflineNotice isPage />;
        }

        return (
            <Suspense fallback={null}>
                <Switch>
                    {this.renderComponentsOfType(
                        RouterItemType.SWITCH_ITEMS_TYPE
                    )}
                </Switch>
            </Suspense>
        );
    }
}

export default RouterComponent;
