export const PurchaseFuelActionType = {
    UPDATE_CUSTOMER_CART: 'UPDATE_CUSTOMER_CART',
    UPDATE_PURCHASE_FUEL_STEP: 'UPDATE_PURCHASE_FUEL_STEP',
    UPDATE_ORDERS_HISTORY: 'UPDATE_ORDERS_HISTORY',
    UPDATE_PENDING_ORDER: 'UPDATE_PENDING_ORDER',
    UPDATE_IS_LOADING: 'UPDATE_IS_LOADING',
    UPDATE_PAYMENT_METHODS: 'UPDATE_PAYMENT_METHODS',
    UPDATE_BANK_TRANSFER_DATA: 'UPDATE_BANK_TRANSFER_DATA',
    UPDATE_DELIVERY_DATE: "UPDATE_DELIVERY_DATE",
    RESET_PURCHASE_FUEL_STATE: 'RESET_PURCHASE_FUEL_STATE',
};