import { UrlRewritesComponent as SourceUrlRewritesComponent } from 'SourceRoute/UrlRewrites/UrlRewrites.component';
import { UrlRewritePageType } from 'SourceRoute/UrlRewrites/UrlRewrites.config';

/** @namespace Ennero/Route/UrlRewrites/Component */
export class UrlRewritesComponent extends SourceUrlRewritesComponent {
    
    renderContent() {
        const { type } = this.props;
        
        switch (type) {
        case UrlRewritePageType.PRODUCT:
            return this.renderNoMatch();
        case UrlRewritePageType.CMS_PAGE:
            return this.renderCmsPage();
        case UrlRewritePageType.CATEGORY:
            return this.renderCategoryPage();
        case UrlRewritePageType.NOTFOUND:
            return this.renderNoMatch();
        default:
            return this.renderDefaultPage();
        }
    }
}

export default UrlRewritesComponent;
