import { isInitiallySignedIn } from 'Util/Auth/IsSignedIn';

import { MyAccountActionType } from './MyAccount.type';

/** @namespace Ennero/Store/MyAccount/Reducer/getInitialState */
export const getInitialState = () => ({
    isSignedIn: isInitiallySignedIn(),
    passwordResetStatus: '',
    passwordResetMessage: '',
    isPasswordForgotSend: false,
    isLoading: false,
    isLocked: false,
    customer: {},
    message: '',
    email: '',
    status: false,
    profileImage: ''
});

/** @namespace Ennero/Store/MyAccount/Reducer/MyAccountReducer */
export const MyAccountReducer = (
    state = getInitialState(),
    action,
) => {
    switch (action.type) {
    case MyAccountActionType.UPDATE_CUSTOMER_SIGN_IN_STATUS: {
        const { status } = action;

        return {
            ...state,
            isSignedIn: status,
        };
    }

    case MyAccountActionType.UPDATE_CUSTOMER_PASSWORD_RESET_STATUS: {
        const { status, message } = action;

        return {
            ...state,
            passwordResetStatus: status,
            passwordResetMessage: message,
        };
    }

    case MyAccountActionType.UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS: {
        return {
            ...state,
            isPasswordForgotSend: !state.isPasswordForgotSend,
        };
    }

    case MyAccountActionType.UPDATE_CUSTOMER_DETAILS: {
        const { customer } = action;    
        
        return {
            ...state,
            customer,
        };
    }

    case MyAccountActionType.UPDATE_CUSTOMER_LOCATIONS_COUNT: {
        const { locationscount } = action;

        return {
            ...state,
           customer : {
                ...state.customer,
                locations_count: locationscount
            }
        };
    }

    case MyAccountActionType.UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL: {
        const { email } = action;

        return {
            ...state,
            email,
        };
    }

    case MyAccountActionType.UPDATE_CUSTOMER_IS_LOADING: {
        const { isLoading } = action;

        return {
            ...state,
            isLoading,
        };
    }

    case MyAccountActionType.UPDATE_CUSTOMER_IS_LOCKED: {
        const { isLocked } = action;

        return {
            ...state,
            isLocked,
        };
    }

    case MyAccountActionType.UPDATE_CUSTOMER_PROFILE_IMAGE: {
        const { profileImage  } = action;
        
        return {
            ...state,
            profileImage
        }

    }

    default:
        return state;
    }
};

export default MyAccountReducer;
