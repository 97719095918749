import { servicesActionType } from './Servicestype';

/** @namespace Ennero/Store/Services/Services/Reducer/getInitialState */
export const getInitialState = () => ({
    isLoaded: false,
    selectedService: {},
    isFirstTime: true,
    services: [],
    servicesCount: 0
});

/** @namespace Ennero/Store/Services/Services/Reducer/ServicesReducer */
export const ServicesReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case servicesActionType.GET_SERVICES_DATA: {
            const { services } = action;
            const { isFirstTime } = state;
            const selectedService = isFirstTime ? services[0] : state.selectedService;

            return {
                ...state,
                services,
                selectedService,
                isLoaded: true,
                isFirstTime: false,
                servicesCount: services.length
            };
        }
        
        case servicesActionType.SELECT_SERVICE: {
            const { selectedService } = action;

            return {
                ...state,
                selectedService,
            };
        }

        default:
            return state;
    }
};

export default ServicesReducer;
