import BreadcrumbsReducer from 'SourceStore/Breadcrumbs/Breadcrumbs.reducer';
import CartReducer from 'SourceStore/Cart/Cart.reducer';
import CategoryReducer from 'SourceStore/Category/Category.reducer';
import CheckoutReducer from 'SourceStore/Checkout/Checkout.reducer';
import ConfigReducer from 'SourceStore/Config/Config.reducer';
import ContactFormReducer from 'SourceStore/ContactForm/ContactForm.reducer';
import MetaReducer from 'SourceStore/Meta/Meta.reducer';
import NavigationReducer from 'SourceStore/Navigation/Navigation.reducer';
import NoMatchReducer from 'SourceStore/NoMatch/NoMatch.reducer';
import OfflineReducer from 'SourceStore/Offline/Offline.reducer';
import OverlayReducer from 'SourceStore/Overlay/Overlay.reducer';
import PopupReducer from 'SourceStore/Popup/Popup.reducer';
import ProductCompareReducer from 'SourceStore/ProductCompare/ProductCompare.reducer';
import ProductListInfoReducer from 'SourceStore/ProductListInfo/ProductListInfo.reducer';
import StoreInPickUpReducer from 'SourceStore/StoreInPickUp/StoreInPickUp.reducer';
import UrlRewritesReducer from 'SourceStore/UrlRewrites/UrlRewrites.reducer';
import WishlistReducer from 'SourceStore/Wishlist/Wishlist.reducer';
import MyAccountReducer from 'Store/MyAccount/MyAccount.reducer';

import AppReducer from './app/App.reducer';
import CmsReducer from './Cms/Cms.reducer';
import FuelPriceReducer from './FuelPrice/FuelPrice.reducer';
import LocationsReducer from './locations/Locations.reducer';
import PurchaseFuel from './PurchaseFuel/PurchaseFuel.reducer';
import ServicesReducer from './services/Services.reducer';
import TanksReducer from './tanks/Tanks.reducer'

/** @namespace Ennero/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ProductListInfoReducer,
    CartReducer,
    WishlistReducer,
    NoMatchReducer,
    MyAccountReducer,
    NavigationReducer,
    OverlayReducer,
    OfflineReducer,
    PopupReducer,
    UrlRewritesReducer,
    ConfigReducer,
    MetaReducer,
    CheckoutReducer,
    ContactFormReducer,
    ProductCompareReducer,
    StoreInPickUpReducer,
    BreadcrumbsReducer,
    CategoryReducer,
    CmsReducer,
    LocationsReducer,
    AppReducer,
    PurchaseFuel,
    FuelPriceReducer,
    TanksReducer,
    ServicesReducer
});
