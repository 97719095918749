import { PurchaseFuelActionType } from './PurchaseFuel.type';
/** @namespace Ennero/Store/PurchaseFuel/Reducer/getInitialState */
export const getInitialState = () => ({
    customerCart: { items: [] },
    fuelPurchaseStep: "1",
    ordersHistory: [],
    pendingOrder: {},
    isLoading: false,
    paymentMethods: [],
    banktransferData: { instructions: [] },
    deliveryDate: { startDate: '', endDate: '' }
});
/** @namespace Ennero/Store/PurchaseFuel/Reducer/PurchaseFuelReducer */
export const PurchaseFuelReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case PurchaseFuelActionType.UPDATE_CUSTOMER_CART: {
            const { customerCart } = action;

            return {
                ...state,
                customerCart,
            };
        }

        case PurchaseFuelActionType.UPDATE_IS_LOADING: {
            const { isLoading } = action;

            return {
                ...state,
                isLoading,
            };
        }

        case PurchaseFuelActionType.UPDATE_PURCHASE_FUEL_STEP: {
            const { fuelPurchaseStep } = action;

            return {
                ...state,
                fuelPurchaseStep,
            };
        }
        case PurchaseFuelActionType.UPDATE_ORDERS_HISTORY: {
            const { ordersHistory } = action;

            return {
                ...state,
                ordersHistory,
            };
        }
        case PurchaseFuelActionType.UPDATE_PENDING_ORDER: {
            const { pendingOrder } = action;

            return {
                ...state,
                pendingOrder,
            };
        }

        case PurchaseFuelActionType.UPDATE_PAYMENT_METHODS: {
            const { paymentMethods } = action;

            return {
                ...state,
                paymentMethods,
            };
        }

        case PurchaseFuelActionType.UPDATE_BANK_TRANSFER_DATA: {
            const { banktransferData } = action;

            return {
                ...state,
                banktransferData,
            };
        }

        case PurchaseFuelActionType.UPDATE_DELIVERY_DATE: {
            const { deliveryDate } = action;

            return {
                ...state,
                deliveryDate,
            };
        }
        case PurchaseFuelActionType.RESET_PURCHASE_FUEL_STATE: {

            return {
                ...state,
                ...getInitialState(),
            };
        }

        default:
            return state;
    }
};
export default PurchaseFuelReducer;