export * from 'SourceComponent/Router/Router.config';

export enum RouterSwitchItemType {
    HOME = 'HOME',
    SEARCH = 'SEARCH',
    CMS_PAGE = 'CMS_PAGE',
    CART = 'CART',
    CHECKOUT = 'CHECKOUT',
    CHANGE_PASSWORD = 'CHANGE_PASSWORD',
    CREATE_ACCOUNT = 'CREATE_ACCOUNT',
    LOGIN = 'LOGIN',
    ACCOUNT_FORGOT_PASSWORD = 'ACCOUNT_FORGOT_PASSWORD',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
    CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT',
    MY_ACCOUNT = 'MY_ACCOUNT',
    MY_ACCOUNT_ORDER = 'MY_ACCOUNT_ORDER',
    MY_ACCOUNT_ORDERS = 'MY_ACCOUNT_ORDERS',
    MY_ACCOUNT_DOWNLOADABLE = 'MY_ACCOUNT_DOWNLOADABLE',
    MY_ACCOUNT_WISHLIST = 'MY_ACCOUNT_WISHLIST',
    MY_ACCOUNT_ADDRESS = 'MY_ACCOUNT_ADDRESS',
    MY_ACCOUNT_NEWSLETTER = 'MY_ACCOUNT_NEWSLETTER',
    MENU = 'MENU',
    SHARED_WISHLIST = 'SHARED_WISHLIST',
    CONTACT_PAGE = 'CONTACT_PAGE',
    COMPARE = 'COMPARE',
    STYLE_GUIDE = 'STYLE_GUIDE',
    URL_REWRITES = 'URL_REWRITES',
    LOCATION = 'LOCATION',
    SUPPORT = 'SUPPORT',
    FUEL_PRICE = 'FUEL_PRICE',
    PURCHASE_HISTORY = 'PURCHASE_HISTORY',
    ADDED_SERVICES = 'ADDED_SERVICES',
    REPORTING = 'REPORTING',
}
