/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import { appActionType } from './App.type';

export const getInitialState = () => ({
    isLoaded: false,
    CitiesandProvinces:[],
    provincesArray:[],
    citiesArray:[],
    citiesMapprovincesArray:{},
    provincesList:[],
    provincesCitiesSuburbsData: [],
});

export const AppReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case appActionType.GET_CITIES_PROVINCE_DATA: {
            const { ProductPricing } = action;
            const citiesMapprovincesArray = ProductPricing.reduce((accumulator, currentObj) => ({ ...accumulator, [currentObj.city_name]: [currentObj.province,currentObj.province_code, currentObj.province_id] }), {});
            const filteredProductPricing = ProductPricing.filter(item => item.city_name && item.province && item.province_code);


            return {
                ...state,
                provincesArray: filteredProductPricing .map((item, id) => ({
                    id,
                    label: item.province,
                    value: item.province
                })),
                citiesMapprovincesArray,
                isLoaded: true,
                CitiesandProvinces: ProductPricing,
            }; 
        }

        case appActionType.GET_PROVINCE_CITIES_SUBURBS_DATA: {
            const { provinces } = action;

            if (!Array.isArray(provinces)) {
                return state;
            }

            const provincesList = [];
            const citiesArray = [];

            provinces.forEach((province) => {
                const labelWithoutComingSoon = province.name.includes(' - coming soon')
                ? province.name.split(' - coming soon')[0].trim()
                : province.name;

                provincesList.push({
                    id: province.id,
                    label: labelWithoutComingSoon,
                    value: province.code,
                    is_supported: province.is_supported === 1,
                });

                if (province && Array.isArray(province.Cities)) {
                    const { Cities } = province;

                    // Collect all cities
                    Cities.forEach((city) => {
                        if (city && city.id && city.name) {
                            citiesArray.push({
                                id: city.id,
                                label: city.name,
                                value: city.name,
                                is_supported: city.is_supported,
                            });
                        }
                    });
                }
            });

        
        
            return {
                ...state,
                provincesList,
                citiesArray,
                provincesCitiesSuburbsData: provinces,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};

export default AppReducer;
