import {
    SharedTransitionComponent as SourceSharedTransitionComponent,
} from 'SourceComponent/SharedTransition/SharedTransition.component';

import './SharedTransition.override.style';

/** @namespace Ennero/Component/SharedTransition/Component */
export class SharedTransitionComponent extends SourceSharedTransitionComponent {
};

export default SharedTransitionComponent;
