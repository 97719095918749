import {
    CmsActionType,
} from './Cms.type';

/** @namespace Ennero/Store/Cms/Reducer/getInitialState */
export const getInitialState = () => {
    const {
        actionName: {
            cmsPage = {},
        } = {},
    } = window;

    return {
        cmsPage,
        isLoading: !Object.keys(cmsPage)?.length,
    };
};

/** @namespace Ennero/Store/Cms/Reducer/CmsReducer */
export const CmsReducer = (
    state = getInitialState(),
    action,
) => {
    switch (action.type) {
    case CmsActionType.UPDATE_CMS_PAGE: {
        const { cmsPage = {} } = action;

        return {
            ...state,
            cmsPage,
            isLoading: false,
        };
    }

    case CmsActionType.UPDATE_CMS_BLOCK: {
        const { cmsBlock = {} } = action;

        return {
            ...state,
            cmsBlock: {
                ...cmsBlock,
                ...state.cmsBlock
            },
            isLoading: false,
        };
    }

    case CmsActionType.UPDATE_CMS_PAGE_LOADING: {
        const { isLoading } = action;

        return {
            ...state,
            isLoading,
        };
    }

    default:
        return state;
    }
};

export default CmsReducer;
